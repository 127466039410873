<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <b-row class="mb-2">
                <b-col md="4">
                    <h2><strong>Scan Attendance</strong></h2>
                </b-col>
            </b-row>

       
            <div class="card">
                <div class="card-body">
                    <StreamBarcodeReader
                        @decode="(a, b, c) => onDecode(a, b, c)"
                        @loaded="() => onLoaded()"
                        ></StreamBarcodeReader>
                        <div class="text-center" v-if="this.load!=1">
                            <strong>Initializing Camera...</strong>
                            <div class="spinner-border text-primary" role="status" aria-hidden="true"></div>
                        </div>

                </div>
            </div>
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    // import { ImageBarcodeReader } from "vue-barcode-reader";
    import { StreamBarcodeReader } from "vue-barcode-reader";

    export default {
        components: {
            StreamBarcodeReader,
        },
        data() {
            return {
                is_busy: false,
                editMode: false,
                title: '',
                text: "",
                id: null,
                is_busy: false,
                user: '',
                load: ''
            }
        },

        created() {
          this.roleHelper(['1', '2', '5']);
        },

        mounted() {
            this.user = JSON.parse(localStorage.getItem('user')).data.user
            this.school = JSON.parse(localStorage.getItem('user')).data.school
        },

        props: {
            msg: String,
        },
        methods: {
            onDecode (result) 
            { 
                if(this.is_busy) return;
                this.is_busy = true;
                axios.get('/attendance/mark/' + result + '/' + this.user.username)
                .then((response)=>{ 
                    Swal.fire(
                        "Created!",
                        response.data.message,
                        "success"
                    );
                })
                .catch((err)=>{
                    Swal.fire(
                        "Error!",
                        err.response.data.data.error,
                        "error"
                    );
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },

            onLoaded() {
                this.load = 1;
                console.log(this.load)
            },
        },
    }
</script>
<style>
    .cont {
        background: #F3F2F7;
    }

    .def {
        width: 70px;
    }
</style>